/*******************************/
/********* General CSS *********/
/*******************************/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  /* color: #666666; */
  background: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'EB Garamond', serif;
  font-weight: 600;
  font-style: italic;
  color: #121518;

}

.colo {
  color: #000000;
}

.colo:hover {
  color: #000000;
}

.colors {
  color: #fff;
}

a {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  transition: .3s;
  font-size: 16px;
}

a:hover,
a:active,
a:focus {
  color: #fff;
  outline: none;
  text-decoration: none;
}

.btn:focus {
  box-shadow: none;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  /* position: unset;  */
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.wrapper {
  /* position: relative; */
  width: 100%;
  /* max-width: 1366px; */
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
}

.back-to-top {
  position: fixed;
  display: none;
  /* background: #aa9166; */
  background: #000;
  color: #aa9166;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 22px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 9;
}

.back-to-top:hover {
  color: #aa9166;
  background: #121518;
}

.back-to-top i {
  padding-top: 10px;
}

p,
li {
  text-align: justify;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;

}

h2 {
  margin-top: 10px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

th,
td {
  text-align: center;
}

.navbar-expand {

  background-color: antiquewhite;
}

/**********************************/
/********** Top Bar CSS ***********/
/**********************************/
.top-bar {
  position: relative;
  background: #000000;
  padding: 15px;

}

.Top_bar_display {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content:space-evenly;
  align-items: center;
}


.top-bar .logo {
  padding: 0px;
  text-align: left;
  overflow: hidden;
}


.top-bar .logo h1 {
  margin: 0;
  color: #aa9166;
  font-size: 75px;
  line-height: 55px;
  font-weight: 800;
}

.top-bar .logo img {
  max-width: 100%;
  max-height: 90px;
}

.top-bar .top-bar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.top-bar .text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  
}

.top-bar .text h3 {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  margin: 0;
  margin-right: 30px;
}

.top-bar .text p {
  color: #ffffff;
  font-size: 13px;
  margin: 0;
}

.top-bar .social {
  display: flex;
  height: 90px;
  font-size: 0;
  justify-content: flex-end;
}

.top-bar .social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  font-size: 22px;
  color: #ffffff;
  border-right: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .social a:first-child {
  border-left: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .social a:hover {
  color: #aa9166;
  background: #ffffff;
}

@media (max-width: 992px) {
  .top-bar .text h2 {
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 15px;
    margin: 0;
    margin-right: 30px;
  }

}

@media (max-width: 991.98px) {
  .top-bar .logo {
    text-align: center;
  }

  .top-bar .social {
    display: none;
  }

  .display {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }

}


/**********************************/
/*********** Nav Bar CSS **********/
/**********************************/
.carousel-indicators {
  position: none;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.navbar {
  background-color: #000 !important;
  padding: -10px;
  box-shadow: 0px 10px -14px 14px #FFF;
  transition: box-shadow 0.3s ease;
  z-index: index auto;
  ;
  width: 100%;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 10px -14px 14px #FFF;
}

.nav-bar {
  transition: .3s;
  z-index: 1;
  width: 100%;
  border-bottom: 0.1px solid white;

}

.bars-color {
  color: white;
  border-color: white;
}

.justify-content-center {
  color: black;
  /* margin-top: 22px; */
}

.Nav_center {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.Nav_center,
.dropdown:hover {
  color: #FFFFFF;
  font-size: 13px !important;
}

.rights {
  background-color: red;
  color: white;
  margin-left: 80%;

}

.calendly-inline-widget {
  width: 100%;
  margin-bottom: -30px;


}

/* .btn{
  margin-right: -77px;
} */
.navbar-expand-lg {}

.btns :hover {
  color: #121518;

}

.nav-bar.nav-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px 0;
  box-shadow: 0px 10px -14px 14px #FFF;
  z-index: 999;
}

.nav-item {
  color: #aa9166;
  font-weight: 550;
}

.logo img {
  width: 120px;
  /* height: 70px;
  border-radius: 50px; */
}

.logos img {
  width: 120px;
  /* height: 70px;
  border-radius: 50px; */
}

.nav-bar .navbar {
  height: 100%;
  padding: 0;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  padding: 10px 10px 8px 10px;
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fbfaf9;
  transition: none;
}

.nav-bar .dropdown-menu {
  margin-top: 0;
  border: 0;
  border-radius: 0;
  background: #aa9166;
}

.nav-bar .btn {
  color: #0e0a0a;
  border: 2px solid #aa9166;
  border-radius: 0;
}

.nav-bar .btn:hover {
  background: #0a0a09;
}

@media (min-width: 992px) {
  .nav-bar {

    padding: 10px 60px 0px 50px;
    box-shadow: 2px 10px 80px 0px black;
  }

  .nav-bar.nav-sticky {
    padding: 10px 60px;
  }

  .nav-bar,
  .nav-bar .navbar {
    background: #000 !important;
    box-shadow: 0px 10px -14px 14px #FFF;
  }

  .nav-bar .navbar-brand {
    display: none;
  }

  .nav-bar a.nav-link {
    padding: 8px 15px;
    font-size: 15px;
    text-transform: uppercase;

  }

  .nav-bar a.nav-link:hover {
    padding: 8px 15px 10px 15px;
    /* font-size: 16px; */
    text-transform: uppercase;
    text-decoration: underline;
  }

  .calendly-inline-widget {
    min-width: 320px;
    height: 630px;

  }
}

@media (max-width: 991.98px) {

  .nav-bar,
  .nav-bar .navbar {
    padding: 3px 0;
    background: #000 !important;

  }

  .nav-bar a.nav-link {
    padding: 5px;
  }

  .nav-bar .dropdown-menu {
    box-shadow: none;
  }

  .nav-bar .btn {
    display: none;
  }

  .Nav_center {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .logos img {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    display: none;
  }

  .Pl9F4D0kOr1s0KUQ1VhD {
    max-width: 860px;
    margin-right: 20px;
    margin-left: 10px;
    padding: 25px 15px;
  }

}


/*******************************/
/******** Carousel CSS *********/
/*******************************/
/* Set the background of carousel to transparent */
#carousel {
  background-color: transparent;
  z-index: 0;
}

/* Set the background of carousel items to transparent */
.carousel-item {
  background-color: transparent;
}

.carousel-item img {
  width: 100%;
}

/* Set the background of carousel controls to transparent */
.carousel-control-prev,
.carousel-control-next {
  background-color: transparent;
}

/* Remove the box-shadow that may be causing the white color to appear */
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  box-shadow: none !important;
}


.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right,
.carousel-item-next.carousel-item-right,
.carousel-item-prev.carousel-item-left {
  position: absolute;
  top: 0;
  transform: translateX(0);
  width: 100%;
  display: block;
  opacity: 0;
  box-shadow: none !important;
}

.carousel-item-next,
.carousel-item-prev {
  position: relative;
  box-shadow: none !important;
}

.carousel-inner .active.carousel-item+.carousel-item {
  position: absolute;
  top: 0;
  opacity: 0;
  box-shadow: none !important;
}

.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left):not(.carousel-item-next):not(.carousel-item-prev) {
  opacity: 1;
  box-shadow: none !important;
}

.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev,
.carousel-inner .carousel-item.active.carousel-item-right,
.carousel-inner .carousel-item.active.carousel-item-left {
  transform: translateX(0);
  box-shadow: none !important;
}

.carousel-control-prev,
.carousel-control-next {
  z-index: 1;
  box-shadow: none !important;
}


/*******************************/
/******* Page Header CSS *******/
/*******************************/
.page-header {
  /* position: relative; */
  margin-bottom: 45px;
  padding: 90px 0;
  text-align: center;
  background: #aa9166;
}

.center {
  display: flex;
  justify-content: center;
}

.page-header h2 {
  /* position: relative; */
  color: #121518;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.page-header h2::after {
  /* position: absolute; */
  content: "";
  width: 100px;
  height: 2px;
  left: calc(50% - 50px);
  bottom: 0;
  background: #121518;
}

.page-header a {
  /* position: relative; */
  padding: 0 12px;
  font-size: 22px;
  color: #121518;
}

.page-header a:hover {
  color: #ffffff;
}

.page-header a::after {
  /* position: absolute; */
  content: "/";
  width: 8px;
  height: 8px;
  top: -2px;
  right: -7px;
  text-align: center;
  color: #121518;
}

.page-header a:last-child::after {
  display: none;
}

@media (max-width: 767.98px) {
  .page-header h2 {
    font-size: 35px;
  }

  .page-header a {
    font-size: 18px;
  }
}


/*******************************/
/******* Section Header ********/
/*******************************/
.section-header {
  /* position: relative; */
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
}

.section-header h2 {
  margin: 0;
  /* position: relative; */
  font-size: 50px;
  font-weight: 700;
  white-space: nowrap;
  z-index: 1;
}

.section-header h2::before {
  /* position: absolute; */
  content: "";
  width: 100%;
  height: 7px;
  left: 0;
  top: 21px;
  background: transparent;
  border-top: 1px solid #aa9166;
  border-bottom: 1px solid #aa9166;
  z-index: -1;
}

.section-header h2::after {
  /* position: absolute; */
  content: "";
  width: 100%;
  height: 7px;
  left: 0;
  bottom: 20px;
  background: transparent;
  border-top: 1px solid #aa9166;
  border-bottom: 1px solid #aa9166;
  z-index: -1;
}

@media (max-width: 767.98px) {
  .section-header h2 {
    font-size: 30px;
  }
}

/*******************************/
/******** Top Feature CSS ******/
/*******************************/
.feature-top {
  /* position: relative; */
  /* background: #121518; */
  margin:15px 0px 15px 0px;
}

.feature-top .col-md-3 {
  border-right: 1px solid rgba(255, 255, 255, .1);
  border-bottom: 1px solid rgba(255, 255, 255, .1);
}

@media (max-width: 575.98px) {
  .feature-top .col-md-3:nth-child(1n) {
    border-right: none;
  }

  .feature-top .col-md-3:last-child {
    border-bottom: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .feature-top .col-md-3:nth-child(2n) {
    border-right: none;
  }

  .feature-top .col-md-3:nth-child(3n),
  .feature-top .col-md-3:nth-child(4n) {
    border-bottom: none;
  }
}

@media (min-width: 768px) {
  .feature-top .col-md-3 {
    border-bottom: none;
  }

  .feature-top .col-md-3:nth-child(4n) {
    border-right: none;
  }
}

/* .feature-top .feature-item {
  padding: 30px 0;
  text-align: center;
} */
.feature-top .feature-item {
  padding: 30px 0;
  text-align: center;
  border: 1px solid;
  box-shadow: 3px 3px 3px 8px #aa9166;
  height: 10rem;
  background: black;
}

.feature-top .feature-item i {
  color: #aa9166;
  font-size: 35px;
  margin-bottom: 10px;
}

.far:hover {
  /* margin-top: -10px; */
}

.feature-top .feature-item h3 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
}

.feature-top .feature-item p {
  color: #999999;
  margin: 0;
  font-size: 18px;
}

.ReactModal__Content .ReactModal__Content--after-open {
  /* position: absolute; */
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(38, 34, 34);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;

}


/*******************************/
/********** About CSS **********/
/*******************************/
.about {
  /* position: relative; */
  width: 100%;
  padding: 45px 0;
}

.about .section-header {
  margin-bottom: 30px;
}

.about .about-img {

  height: 100%;
  padding: 10px;
  /* background: #121518; */
  border-radius: 10px;
  /* overflow: hidden; */
}



.align-items {
  flex-direction: row-reverse;
}

.about .about-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about .about-text p {
  font-size: 16px;
}

.about .about-text1 p {
  font-size: 16px;
}

.about .about-text a.btn {

  margin-top: 15px;
  padding: 15px 35px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #aa9166;
  border: 2px solid #aa9166;
  border-radius: 0;
  background: #121518;
  transition: .3s;
}

.about .about-text a.btn:hover {
  color: #121518;
  background: #aa9166;
}

@media (max-width: 767.98px) {
  .about .about-img {
    margin-bottom: 30px;
    height: auto;
  }
}

.col-md-8 {
  justify-content: flex-start;
}

.button {
  border-radius: 4px;
  background-color: #4c4543;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 28px;
  padding: 0px;
  width: 160px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button:hover {
  color: #aa9166;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.3s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

/*******************************/
/********* Service CSS *********/
/*******************************/
.service {

  width: 100%;
  padding: 45px 0 15px 0;
}

.Ourimages {
  width: 300px;
  height: 300px;
}

.service .service-item {

  width: 100%;
  height: 90%;
  text-align: center;
  background: #121518;
  margin:30px 0px;

}

.service .service-icon {
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #000000; */
}

.service .service-icon i {
  width: 100px;
  height: 100px;
  padding: 20px 0;
  text-align: center;
  font-size: 60px;
  color: #aa9166;
  background: #121518;
  transition: .5s;
}

.service .service-item:hover .service-icon i {
  margin-top: -15px;
}

.service .service-item h3 {
  margin: 0;
  padding: 25px 15px 15px 15px;
  font-size: 30px;
  font-weight: 600;
  color: #aa9166;
}

.service .service-item p {
  margin: 0;
  text-align: justify;
  color: #999999;
  padding: 0 25px 25px 25px;
  font-size: 18px;
}

.service .service-item a.btn {
  margin-bottom: 30px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #aa9166;
  border: 2px solid #aa9166;
  border-radius: 0;
  background: #121518;
  transition: .3s;
}

.service .service-item:hover a.btn {
  color: #121518;
  background: #aa9166;
  position: relative;
  bottom: 3px;
}

.conf{
  
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

}


/*******************************/
/********* Feature CSS *********/
/*******************************/
.feature {
  /* position: relative; */
  width: 100%;
  padding: 45px 0;
}

.feature .feature-item {
  margin-bottom: 30px;
}

.feature .feature-icon {
  height: 180px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #121518;
}

.feature .feature-icon i {
  width: 100px;
  height: 100px;
  padding: 20px 0;
  text-align: center;
  font-size: 60px;
  color: #aa9166;
  /* background: #000000; */
  transition: .5s;
}

.feature .feature-item:hover .feature-icon i {
  margin-right: -15px;
}

.feature .feature-item h3 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
}

.feature .feature-item h3::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  left: 0;
  bottom: 0;
  background: #aa9166;
}

.feature .feature-item p {
  margin: 0;
  font-size: 16px;
}

.feature .feature-img {
  /* position: relative; */
  height: 100%;
  padding: 10px;
  background: #121518;
}

.feature .feature-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media(max-width: 767.98px) {
  .faqs .faqs-img {
    margin-bottom: 30px;
    height: auto;
  }
}

/*******************************/
/*********** Team CSS **********/
/*******************************/
.team {
  /* position: relative; */
  width: 100%;
  padding: 45px 0 15px 0;
}

.team .team-item {
  margin-bottom: 30px;
  border: 1px solid;
  border-radius: 7px;
}

.team .team-img {
  /* position: relative; */
}

.team .team-img img {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
}

.team .team-text {

  padding: 15px;
  text-align: center;
  background: #121518;
}

.team .team-text h2 {
  font-size: 25px;
  font-weight: 700;
  color: #aa9166;
}

.team .team-text p {
  margin: 0;
  color: #fff;
  text-align: center;
}

.team .team-social {
      /* position: relative; */
    /* width: calc(100% - 60px); */
    height: 45px;
    margin: 5px;
    /* top: -114px; */
    /* left: 30px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #aa9166;
    font-size: 0;
    transition: .5s;
}

.team .team-item:hover .team-social {
  width: 100%;
  left: 0;
}

.team .team-social a {
  display: inline-block;
  margin-right: 15px;
  font-size: 18px;
  text-align: center;
  color: #121518;
  transition: .3s;
}

.team .team-social a:last-child {
  margin-right: 0;
}

.team .team-social a:hover {
  color: #ffffff;
}


/*******************************/
/*********** FAQs CSS **********/
/*******************************/
.faqs {
  /* position: relative; */
  width: 100%;
  padding: 45px 0;
}

.faqs .faqs-img {
  /* position: relative; */
  height: 100%;
  padding: 10px;
  background: #121518;
}

.faqs .faqs-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media(max-width: 767.98px) {
  .faqs .faqs-img {
    margin-bottom: 30px;
    height: auto;
  }
}

.faqs .card {
  margin-bottom: 15px;
  padding-top: 15px;
  border: none;
  border-radius: 0;
  border-top: 1px solid #aa9166;
}

.faqs .card:last-child {
  margin-bottom: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #aa9166;
}


.faqs .card-header {
  padding: 0;
  border: none;
  background: #ffffff;
}

.faqs .card-header a {
  display: block;
  width: 100%;
  color: #121518;
  font-size: 18px;
  line-height: 40px;
}

.faqs .card-header a span {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  text-align: center;
  background: #121518;
  color: #aa9166;
  font-weight: 700;
}

.faqs .card-header [data-toggle="collapse"]:after {
  font-family: 'font Awesome 5 Free';
  content: "\f067";
  float: right;
  color: #121518;
  font-size: 12px;
  font-weight: 900;
  transition: .3s;
}

.faqs .card-header [data-toggle="collapse"][aria-expanded="true"]:after {
  font-family: 'font Awesome 5 Free';
  content: "\f068";
  float: right;
  color: #121518;
  font-size: 12px;
  font-weight: 900;
  transition: .3s;
}

.faqs .card-body {
  padding: 15px 0 0 0;
  font-size: 16px;
  border: none;
  background: #ffffff;
}

.faqs a.btn {
  /* position: relative; */
  margin-top: 15px;
  padding: 15px 35px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #aa9166;
  border: 2px solid #aa9166;
  border-radius: 0;
  background: #121518;
  transition: .3s;
}

.faqs a.btn:hover {
  color: #121518;
  background: #aa9166;
}


/*******************************/
/******* Testimonial CSS *******/
/*******************************/
.testimonial {
  position: relative;
  padding: 45px 0;
}

.testimonial .testimonials-carousel {
  position: relative;
  width: calc(100% + 30px);
  left: -15px;
  right: -15px;
}

.testimonial .testimonial-item {
  position: relative;
  margin: 0 15px;
  padding: 30px;
  background: #121518;
  overflow: hidden;
}

.testimonial .testimonial-item i {
  position: absolute;
  top: 0;
  right: 0;
  color: #333333;
  font-size: 60px;
  transform: rotate(45deg);
  z-index: 1;
}

.testimonial .testimonial-item .col-12 {
  margin-top: 20px;
}

.testimonial .testimonial-item img {
  width: 100%;
}

.testimonial .testimonial-item h2 {
  color: #aa9166;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}

.testimonial .testimonial-item p {
  color: #999999;
  font-size: 16px;
  margin: 0;
}

.testimonial .owl-nav,
.testimonial .owl-dots {
  margin-top: 15px;
  text-align: center;
}

.testimonial .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #aa9166;
}

.testimonial .owl-dot.active {
  background: #121518;
}


/*******************************/
/*********** Blog CSS **********/
/*******************************/
/* .blog {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.blog .blog-carousel {
  position: relative;
  width: calc(100% + 30px);
  left: -15px;
  right: -15px;
}

.blog .blog-item {
  position: relative;
  margin: 0 15px;
  background: #ffffff;
  overflow: hidden;
}

.blog .blog-page .blog-item {
  margin: 0 0 30px 0;
}

.blog .blog-item img {
  width: 100%;
  margin-bottom: 25px;
}

.blog .blog-item h3 {
  font-size: 25px;
  font-weight: 600;
}

.blog .blog-item .meta {
  padding: 0 0 15px 0;
}

.blog .blog-item .meta * {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #999999;
}

.blog .blog-item .meta i {
  margin: 0 2px 0 10px;
}

.blog .blog-item .meta i:first-child {
  margin-left: 0;
}

.blog .blog-item p {
  font-size: 16px;
}

.blog .blog-item a.btn {
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #aa9166;
  transition: .3s;
}

.blog .blog-item a.btn i {
  margin-left: 10px;
}

.blog .blog-item a.btn:hover {
  color: #121518;
}

.blog .owl-nav,
.blog .owl-dots {
  margin-top: 15px;
  text-align: center;
}

.blog .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #aa9166;
}

.blog .owl-dot.active {
  background: #121518;
}

.blog .pagination .page-link {
  color: #121518;
  border-radius: 0;
  border-color: #aa9166;
}

.blog .pagination .page-link:hover,
.blog .pagination .page-item.active .page-link {
  color: #121518;
  background: #aa9166;
}

.blog .pagination .disabled .page-link {
  color: #999999;
} */


/*******************************/
/********* Timeline CSS ********/
/*******************************/
.timeline {
  /* position: relative; */
  padding: 45px 0;
}

.timeline .timeline-start {
  /* position: relative; */
  /* width: calc(100% + 30px);
  left: -15px;
  right: -15px; */
}

.timeline .timeline-container {
  padding: 0 15px;
  position: relative;
  background: inherit;
  width: 50%;
  margin-top: -5px;
  z-index: 0;
}

.timeline .timeline-container.left {
  left: 0;
}

.timeline .timeline-container.right {
  left: 50%;
}

.timeline .timeline-container::before {
  content: '';
  position: absolute;
  width: 15px;
  height: 5px;
  background: #aa9166;
  z-index: 0;
}

.timeline .timeline-container.left::before {
  top: 0;
  right: 0;
}

.timeline .timeline-container.right::before {
  bottom: 0;
  left: 0;
}

.timeline .timeline-container::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 5px;
  background: #aa9166;
  z-index: 1;
}

.timeline .timeline-container.left::after {
  bottom: 0;
  right: 0;
}

.timeline .timeline-container.right::after {
  top: 0;
  left: 0;
}

.timeline .timeline-container:first-child::before,
.timeline .timeline-container:last-child::before {
  display: none;
}

.timeline .timeline-container .timeline-content {
  padding: 30px;
  background: #121518;
  /* position: relative; */
  border-right: 5px solid #aa9166;
}

.timeline .timeline-container.right .timeline-content {
  border-left: 5px solid #aa9166;
}

.timeline .timeline-container .timeline-content h2 {
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 600;
  color: #aa9166;
}

.timeline .timeline-container .timeline-content h2 span {
  display: inline-block;
  margin-right: 15px;
  padding: 5px 10px;
  color: #121518;
  background: #aa9166;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
}

.timeline .timeline-container .timeline-content p {
  margin: 0;
  font-size: 16px;
  color: #999999;
}

@media (max-width: 767.98px) {
  .timeline .timeline-container {
    width: 100%;
    margin-top: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    z-index: 0;
  }

  .timeline .timeline-container:last-child {
    padding-bottom: 0;
  }

  .timeline .timeline-container.right {
    left: 0%;
  }

  .timeline .timeline-container.left::after,
  .timeline .timeline-container.right::after,
  .timeline .timeline-container.left::before,
  .timeline .timeline-container.right::before {
    width: 5px;
    height: 35px;
    left: 15px;
  }

  .timeline .timeline-container.left .timeline-content,
  .timeline .timeline-container.right .timeline-content {
    border-left: 5px solid #aa9166;
    border-right: none;
  }
}


/*******************************/
/******** Portfolio CSS ********/
/*******************************/
.portfolio {
  position: relative;
  padding: 45px 0;
  background: #ffffff;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: -15px 0 25px 0;
  list-style: none;
  font-size: 0;
  text-align: center;
}

.portfolio #portfolio-flters li,
.portfolio .load-more .btn {
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  padding: 8px 15px;
  color: #aa9166;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 0;
  background: #121518;
  border: 2px solid #aa9166;
  transition: .3s;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #aa9166;
  color: #121518;
}

.portfolio .load-more {
  text-align: center;
}

.portfolio .load-more .btn {
  padding: 15px 35px;
  font-size: 16px;
  transition: .3s;
}

.portfolio .load-more .btn:hover {
  color: #121518;
  background: #aa9166;
}

.portfolio .portfolio-item {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  transition: .5s;
}

.portfolio .portfolio-item:hover {
  margin-top: -10px;
}

.portfolio .portfolio-wrap {
  position: relative;
  width: 100%;
}

.portfolio img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio figure {
  position: absolute;
  padding: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .0), rgba(0, 0, 0, 1) 90%);
}

.portfolio figure p {
  position: relative;
  margin-bottom: 5px;
  padding-bottom: 5px;
  color: #ffffff;
  font-size: 18px;
}

.portfolio figure p::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  left: 0;
  bottom: 0;
  background: #ffffff;
}

.portfolio figure a {
  font-family: 'EB Garamond', serif;
  color: #aa9166;
  font-size: 25px;
  font-weight: 600;
  font-style: italic;
  transition: .3s;
}

.portfolio figure a:hover {
  text-decoration: none;
  color: #ffffff;
}

.portfolio figure span {
  position: relative;
  color: #999999;
  font-size: 16px;
}


/*******************************/
/********* Contact CSS *********/
/*******************************/
.contact {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

iframe {
  border: 0;
  /* display: flex; */
  width: 100%;
  padding: 30px;
}

.contact .contact-info {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  padding: 30px;
  /* background: #121518; */
}

.contact .contact-item {
  position: relative;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 2px solid #aa9166;
}

.contact .contact-item i {
  display: inline-block;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #aa9166;
  /* border: 2px solid #aa9166; */
}

.contact .contact-text {
  position: relative;
  width: auto;
  padding-left: 15px;
}

.contact .contact-text h2 {
  color: #aa9166;
  font-size: 25px;
  font-weight: 600;
}

.contact .contact-text p {
  margin: 0;
  color: #000000;
  font-size: 16px;
}

.contact .contact-item:last-child {
  margin-bottom: 0;
}

.contact .contact-form {
  position: relative;
  margin-bottom: 30px;
}

.contact .contact-form input {
  height: 60px;
  border-radius: 0;
  border-width: 2px;
  border-color: #121518;
}

.contact .contact-form textarea {
  height: 190px;
  border-radius: 0;
  border-width: 2px;
  border-color: #121518;
}

.contact .contact-form .btn {
  padding: 16px 35px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #aa9166;
  background: #121518;
  border: 2px solid #aa9166;
  border-radius: 0;
  transition: .3s;
}

.contact .contact-form .btn:hover {
  color: #121518;
  background: #aa9166;
}

/* Add your general styling here */

.contact-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 10px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.contact-info h2 {
  margin-bottom: 15px;
}

.contact-info h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.contact-info h4 {
  margin-top: 15px;
  margin-bottom: 5px;
}

.contact-info p {
  margin: 5px 0;
  color: #000000;
  display: flex;
  align-items: center;
}

.contact-info a {
  color: #000000;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.Calendly-popup {
  background-color: rgba(0, 0, 0, 0.8); /* Replace with your desired background color */
}

.icon {
  margin-right: 10px;
  font-size: 18px;
  color: #aa9166;
}

.space{
  padding: 10px;
    color: black;
    font-weight: 500;
}



/*******************************/
/******* Single Page CSS *******/
/*******************************/
.single {
  position: relative;
  width: 100%;
  padding: 45px 0 65px 0;
}

.single img {
  margin: 10px 0 15px 0;
}

.single .table,
.single .list-group {
  margin-bottom: 30px;
}

.single .ul-group {
  padding: 0;
  list-style: none;
}

.single .ul-group li {
  margin-left: 2px;
}

.single .ul-group li::before {
  position: relative;
  content: "\f0da";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.single .ol-group {
  padding-left: 18px;
}


/*******************************/
/********** Newsletter *********/
/*******************************/
.newsletter {
  position: relative;
  max-width: 900px;
  margin: 45px auto -140px auto;
  padding: 30px 15px;
  background: #aa9166;
  z-index: 1;
}

.newsletter .section-header {
  margin-bottom: 25px;
}

.newsletter .section-header h2::before,
.newsletter .section-header h2::after {
  border-color: #ffffff;
}

.newsletter .form {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

.newsletter input {
  height: 50px;
  border: 2px solid #121518;
  border-radius: 0;
}

.newsletter .btn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 40px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #aa9166;
  background: #121518;
  border-radius: 0;
  border: 2px solid #aa9166;
  transition: .3s;
}

.newsletter .btn:hover {
  color: #121518;
  background: #aa9166;
}


/*******************************/
/********* Footer CSS **********/
/*******************************/
.footer {
  background: #121518;
  color: #ffffff;
  font-size: 14px;
  text-align: justify;
}

.footer .footer-about,
.footer .footer-link,
.footer .footer-contact {
  margin-bottom: 45px;
  color: #999999;
}

.footer .footer-about h2,
.footer .footer-link h2,
.footer .footer-contact h2 {
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: 600;
  color: #aa9166;
}

.footer-contact-us h2 {
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: 600;
  color: #aa9166;
}


.footer .footer-link a {
  display: block;
  margin-bottom: 10px;
  transition: .3s;
}

.footer .footer-link a::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .footer-link a:hover {
  color: #aa9166;
  letter-spacing: 1px;
}

.footer .footer-contact p i {
  width: 25px;
}

.footer .footer-social {
  position: relative;
  margin-top: 20px;
}

.footer .footer-social a {
  display: inline-block;
}

.footer .footer-social a i {
  margin-right: 15px;
  font-size: 18px;
  color: #aa9166;
}

.footer .footer-social a:last-child i {
  margin: 0;
}

.footer .footer-social a:hover i {
  color: #999999;
}

.footer .footer-menu .f-menu {
  position: relative;
  padding: 15px 0;
  font-size: 0;
  text-align: center;
  color: #3b3939;
}

.footer .footer-menu .f-menu a {
  font-size: 16px;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .3);
}

.footer .footer-menu .f-menu a:hover {
  color: #aa9166;
}

.footer .footer-menu .f-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}


.footer .copyright {
  padding: 30px 15px;
}

.footer .copyright p {
  margin: 0;
  color: #999999;
}

.footer .copyright .col-md-6:last-child p {
  text-align: right;
}

.footer .copyright p a {
  color: #aa9166;
  font-weight: 500;
  letter-spacing: 1px;
}

.footer .copyright p a:hover {
  color: #ffffff;
}

@media (max-width: 768px) {

  .footer .copyright p,
  .footer .copyright .col-md-6:last-child p {
    margin: 5px 0;
    text-align: center;
  }
}



/* button */
.button {
  border-radius: 4px;
  background-color: #f4511e;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 24px;
  /* padding: 20px; */
  width: 210px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
  color: #000000;
}

.button:hover span:after {
  opacity: 1;
  right: 0;

}



/* calendly */

select {
  word-wrap: normal;
  background: transparent;
  border: none;


}



.get-appointment {
  display: flex;
  justify-content: center;
  gap: 50px;
  /* margin: 0px 0px -50px 0px; */
  overflow: hidden;
}

.get-appointment .close-btn {
  border-radius: 5px;
  background-color: red;
  cursor: pointer;
}


@media (max-width: 768px) {


  .calendly {
    padding: 0;
    margin: 0;
    width: auto;
    height: auto;
    overflow: hidden;
  }

  .get-appointment {
    display: flex;
    justify-content: center;
    gap: 10px;
    overflow: hidden;
  }
}

/* banner - image */
.banner-image img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
}

/* footer-container */
/* footer.css */

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

.footer-divition {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  justify-content: space-evenly;
}

.footer-about,
.footer-link,
.footer-contact {
  flex-basis: 25%;
}

.footer-about h2,
.footer-link h2,
.footer-contact h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-about p,
.footer-link a,
.footer-contact p {
  color: #fff;
  margin-bottom: 10px;
}

.footer-social a {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}

/* Media Query for Responsive Footer */
@media screen and (max-width: 768px) {


  .footer-about,
  .footer-link,
  .footer-contact {
    flex-basis: 100%;
  }

  .footer-social {
    margin-top: 10px;
  }
}


.footer-text {
  font-size: 16px;
}

.footer-about p {
  color: #ffffff;

}

.footer-link a {
  color: #ffffff;

}

.footer-contact-us p {
  color: #fff;
  font-size: 16px;
}

.model-btn {
  color: rgb(170, 145, 102);
  font-size: 16px;
  border: 3px solid white;
  font-weight: bolder;
  border-radius: 5px;
}

/* Preloading CSS */
/* Preloader styles */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff; /* Set the background color you prefer */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the preloader appears above other content */
}

.preloader-logo {
  max-width: 200px; /* Adjust the logo size as needed */
  height: auto;
  transform: translateY(-50%); /* Adjust the value to position the logo */
}

.loading-text {
  margin-top: 20px; /* Adjust margin as needed */
  font-size: 16px; /* Adjust font size as needed */
  font-weight: bold;
}

.loading-dots::after {
  content: "Loading";
  display: inline-block;
  animation: loadingAnimation 1.5s infinite;
}

@keyframes loadingAnimation {
  0%, 20% {
    content: "Loading";
  }
  40% {
    content: "Loading.";
  }
  60% {
    content: "Loading..";
  }
  80% {
    content: "Loading...";
  }
}

/* blog */
.blog-card-image {
  max-width: 100%; /* Ensure the image doesn't exceed the container's width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 10px; /* Add some spacing between image and title */
}

/* If you're using CSS modules in your React component */
.blog-card-image  img{
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}
/* calendly */
.calendly-overlay {
  background: rgba(0, 0, 0, 0.4) !important;
}

.calendly-overlay .calendly-popup {
  box-sizing: border-box;
  position: fixed; /* Use fixed position to cover the whole screen */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  min-width: 300px; /* Adjust the min-width for smaller screens */
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
}

/* Media query for screens with a maximum width of 768px (typical for mobile devices) */
@media (max-width: 768px) {
  .calendly-overlay .calendly-popup {
    min-width: auto; /* Allow the popup to adjust its width based on content */
    max-width: 90%; /* Limit the maximum width for better mobile experience */
  }
}

/* disclaimer */
@media (max-width: 767px) {
  .disclaimer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }

  .disclaimer-container {
    background-color: white;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
}

/* For larger screens */
@media (min-width: 768px) {
  .disclaimer-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15rem;
    margin: 10px;
  }

  .disclaimer-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
}



.disclaimer_link{
  color: blue;
  cursor: pointer;
}

.loading-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  width: 0; /* Start with 0% width */
  background-color: #007bff; /* Adjust color as needed */
  transition: width 0.5s ease; /* Add transition for smooth animation */
}