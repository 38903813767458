.Contactfrom {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 50%;
    /* inset: auto; */
    border: 1px solid rgb(204, 204, 204);
    background: rgb(110 102 121);
    border-radius: 4px;
    outline: none;
    padding: 5px;
}

@media (max-width: 568px) {
    .Contactfrom {
        position: absolute;
        inset: 30px;
        border: 1px solid rgb(204, 204, 204);
        background: rgb(110 102 121);
        border-radius: 4px;
        outline: none;
        padding: 5px;
        margin-top: 60px;
    }
}